<template>
  <div id="map" class="map" ref="map"></div>
</template>

<script>
import L from 'leaflet'

export default {
  name: 'Viewer',
  data() {
    return {
      channel: undefined,
      map: undefined,
      control: undefined,
      untitled: 0,
      listeners: [],
      state: {
        bbox: null,
        channelID: null,
        favcolor: '#000000',
        layers: []
      }
    }
  },
  computed: {},
  mounted() {
    this.initChannel()
    this.initStorage()
    this.initMap()
  },
  methods: {
    initChannel() {
      var parameters = new URLSearchParams(window.location.search)

      // create channel and add listener
      var channelID = parameters.get('channel')
      if (channelID != '' && channelID != null) {
        this.state.channelID = channelID
        this.channel = new BroadcastChannel(channelID)
        var _this = this

        this.channel.onmessage = function (event) {
          if (!event.isTrusted) return
          if (event.data.hasOwnProperty('liveliness')) {
            var c_ack = new BroadcastChannel(event.data.ack_channel)
            c_ack.postMessage({ alive: true }, '*')
            return
          }
          var bounds = event.data.bbox
          var name = event.data.name
          var url = event.data.url
          _this.addMMToMap(url, name, true)
          _this.fitBounds(bounds)
          _this.rewriteURL()
        }
      }
      // add listener as well on postMessage of window
      window.addEventListener(
        'message',
        (event) => {
          var bounds = event.data.bbox
          var name = event.data.name
          var url = event.data.url
          _this.addMMToMap(url, name, true)
          _this.fitBounds(bounds)
          _this.rewriteURL()
        },
        false
      )
    },
    initStorage() {
      var channelID = this.state.channelID
      localStorage['listening'] = JSON.stringify(
        this.localListening(localStorage).concat([channelID])
      )
      localStorage['listening-' + channelID] = 'true'
      var _this = this
      window.addEventListener('beforeunload', (evt) => {
        localStorage['listening-' + channelID] = 'false'
        var firstChannel = _this.localListening(localStorage).indexOf(channelID)
        localStorage['listening'] = JSON.stringify(
          _this
            .localListening(localStorage)
            .filter((_, index) => index != firstChannel)
        )
      })
      window.addEventListener('storage', () => {
        var viewer_registered = _this
          .localListening(localStorage)
          .find((c) => c == channelID)
        if (!viewer_registered)
          localStorage['listening'] = JSON.stringify(
            _this.localListening(localStorage).concat([channelID])
          )
      })
    },
    initMap() {
      // use ref as DOM isn't defined yet
      this.map = L.map(this.$refs['map'])
      this.map.fitWorld()

      // init map and control
      this.control = L.control.layers(null, null, {
        collapsed: false,
        hideSingleBase: true
      })
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(
        this.map
      )
      this.control.addTo(this.map)

      // check query parameters
      // we allow:
      // - bbox= to fit bounds,
      // - url=, urls[0]= for layers
      // - name=, names[0]= for names
      var parameters = new URLSearchParams(window.location.search)

      // update tab color
      var color = parameters.get('color') // hex #000000
      this.updateFavicon(color)

      // find bbox and update map
      var bounds = parameters.get('bbox')
      this.fitBounds(bounds)

      // add layers and update state
      // retro-compat
      var url = parameters.get('url')
      var name = parameters.get('name')
      this.addMMToMap(url, name, true)
      // loop
      var _this = this
      Array.from(parameters)
        .filter(([key, url]) => key.startsWith('urls['))
        .forEach(([key, url]) => {
          var lid = key.slice(5, -1)
          var name = parameters.get('names[' + lid + ']')
          var visible = parameters.get('visibles[' + lid + ']')
          _this.addMMToMap(url, name, visible)
        })
      this.rewriteURL()
      var that = this
      this.map.on('moveend', function (event) {
        that.state.bbox = that.map.getBounds()
        that.rewriteURL()
      })
      this.map.on('overlayadd', function (event) {
        that.state.layers.forEach(function (layer, index) {
          if (layer.layer == event.layer) {
            layer.visible = true
          }
        })
        that.rewriteURL()
      })
      this.map.on('overlayremove', function (event) {
        that.state.layers.forEach(function (layer, index) {
          if (layer.layer == event.layer) {
            layer.visible = false
          }
        })
        that.rewriteURL()
      })
      return true
    },
    updateFavicon(color) {
      if (color != null && color != '') {
        this.state.favcolor = color
      }
      var link = document.querySelector("link[rel~='icon']")
      if (!link) {
        link = document.createElement('link')
        link.setAttribute('rel', 'icon')
        document.head.appendChild(link)
      }

      // create a circle
      var canvas = document.createElement('canvas')
      canvas.width = 16
      canvas.height = 16
      var context = canvas.getContext('2d')
      context.beginPath()
      context.arc(8, 8, 8, 0, 2 * Math.PI, false)
      context.fillStyle = color
      context.fill()

      // update favicon
      link.type = 'image/x-icon'
      link.href = canvas.toDataURL()
    },
    rewriteURL() {
      var parameters = {}
      this.state.layers.forEach(function (el, idx) {
        parameters['urls[' + idx + ']'] = el['url']
        parameters['names[' + idx + ']'] = el['name']
        parameters['visibles[' + idx + ']'] = el['visible']
      })
      if (this.state.bbox != null) {
        parameters['bbox'] = this.state.bbox.toBBoxString()
      }
      if (this.state.favcolor != null) {
        parameters['color'] = this.state.favcolor
      }
      if (this.state.channelID != null) {
        parameters['channel'] = this.state.channelID
      }
      window.history.replaceState(
        {},
        'mappamundi',
        'viewer?' + new URLSearchParams(parameters).toString()
      )
    },
    redoRemoveListeners() {
      const handleRemove = (e) => {
        this.removeMMFromMap(e.target.attributes.getNamedItem('name').nodeValue)
      }

      for (const listener of this.listeners.filter(Boolean)) {
        listener.removeEventListener('click', handleRemove)
      }

      this.listeners = []

      const elements = document.getElementsByClassName('remove')
      for (const element of elements) {
        const listener = element.addEventListener('click', handleRemove)
        this.listeners.push(listener)
      }
    },
    removeMMFromMap(name) {
      var lid = -1
      this.state.layers.forEach(function (layer, index) {
        if (layer.name == name) {
          lid = index
        }
      })
      if (lid == -1) {
        return
      }
      this.map.removeLayer(this.state.layers[lid].layer)
      this.control.removeLayer(this.state.layers[lid].layer)
      this.state.layers.splice(lid, 1)
      this.rewriteURL()
      this.redoRemoveListeners()
    },
    addMMToMap(url, name, visible) {
      // layer
      if (url == '' || url == null) {
        return
      }
      var layer = L.tileLayer(url, {
        tileSize: 512,
        zoomOffset: -1
      })

      if (visible == '' || visible == null || visible == 'true') {
        visible = true
      } else if (visible == 'false') {
        visible = false
      }
      if (visible) {
        layer.addTo(this.map)
      }

      // control
      if (name == '' || name == null) {
        name = 'Untitled ' + this.untitled
        this.untitled += 1
      }

      var name_html = `${name} <span class="remove" name="${name}">&#10005;</span>`

      this.control.addOverlay(layer, name_html)

      // push to state
      this.state.layers.push({
        url: url,
        name: name,
        layer: layer,
        visible: visible
      })
      this.redoRemoveListeners()
    },
    fitBounds(bounds) {
      // position
      if (bounds != '' && bounds != null) {
        var arrbounds = bounds.split(',').map(Number)
        var llbounds = [
          [arrbounds[1], arrbounds[0]],
          [arrbounds[3], arrbounds[2]]
        ]
        this.map.fitBounds(llbounds)
      }
      this.state.bbox = this.map.getBounds()
    },
    localListening(storage) {
      return JSON.parse(storage['listening'] || '[]')
    }
  },
  destroyed() {
    if (this.state.channel) {
      this.state.channel.close()
      localStorage.removeItem('listening-' + color)
    }
  }
}
</script>

<style>
.map {
  height: 100%;
  padding: 0px;
  margin: 0px;
  top: 0px;
  left: 0px;
  width: 100%;
  position: fixed;
}

.remove {
  padding: 5px;
  border-radius: 5px;
}

.remove:hover {
  color: #710000 !important;
  background-color: rgb(239, 239, 239) !important;
}
</style>
